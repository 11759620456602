import axios from 'axios';
import html2pdf from 'html2pdf.js';
import Cookies from 'js-cookie';

import { getPalette } from '../services/palleteGenerator';

/**
 * Get cookie by name
 * we can use it to get django csrf token too
 * @param csrftoken
 */
export const getCookie = (csrftoken) => {
  return Cookies.get(csrftoken);
};

/*
 * Get current Django selected languagd
 * @param {*} defaultLanguage
 * @returns
 */
export const getCurrentLanguageCode = (defaultLanguage = 'fr') => {
  // eslint-disable-next-line no-undef
  const languageCode = document.querySelector('#language-code');

  return languageCode?.length > 0 ? languageCode.value : defaultLanguage;
};

/**
 * Get current site activity title
 * @returns {T|string}
 */
export const getSiteActivityTitle = () => {
  // eslint-disable-next-line no-undef
  const siteActivityTitle = document.querySelector('#activity-title');
  return siteActivityTitle?.value || '';
};

/**
 * Get current site activity description
 * @returns {T|string}
 */
export const getSiteActivityDescription = () => {
  // eslint-disable-next-line no-undef
  const siteActivityDescription = document.querySelector(
    '#activity-description'
  );
  return siteActivityDescription?.value || '';
};

/**
 * Get current site colors
 * @returns {{primaryColor: string, secondaryColor: string, backgroundColor: string}}
 */
const getCurrentSiteColors = () => {
  // eslint-disable-next-line no-undef
  const primaryColor = document.querySelector('#primary-color');
  // eslint-disable-next-line no-undef
  const secondaryColor = document.querySelector('#secondary-color');
  // eslint-disable-next-line no-undef
  const backgroundColor = document.querySelector('#background-color');
  return {
    primaryColor: primaryColor.value,
    secondaryColor: secondaryColor.value,
    backgroundColor: backgroundColor.value,
  };
};

/**
 * Generate CSS variables for primary and secondary color
 */
export const generateCSSVariables = () => {
  const { primaryColor, secondaryColor, backgroundColor } =
    getCurrentSiteColors();
  const primaryPalette = getPalette(primaryColor);
  const secondaryPalette = getPalette(secondaryColor);
  const backgroundPalette = getPalette(backgroundColor);

  //eslint-disable-next-line no-undef
  const root = document.documentElement;

  const steps = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];

  primaryPalette.forEach((color, index) => {
    root.style.setProperty(`--color-primary-${steps[index]}`, color);
  });
  root.style.setProperty('--color-primary-default', primaryColor);
  secondaryPalette.forEach((color, index) => {
    root.style.setProperty(`--color-secondary-${steps[index]}`, color);
  });
  root.style.setProperty('--color-secondary-default', secondaryColor);
  backgroundPalette.forEach((color, index) => {
    root.style.setProperty(`--color-background-${steps[index]}`, color);
  });
  root.style.setProperty('--color-background-default', backgroundColor);
};

/**
 * Get current tenant to use as BASE_API_URL
 * @returns {string}
 */
export const getCurrentTenant = () => {
  // eslint-disable-next-line no-undef
  const hostname = document.querySelector('#hostname');
  return hostname?.value || process.env.BASE_URL;
};

/**
 * Download case summary as PDF
 * @param caseId
 * @returns {Promise<void>}
 */

const isIOS = () => {
  return (
    ['iPhone', 'iPad', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

// Function to download the case summary as PDF
export const downloadCaseSummary = async (caseId) => {
  try {
    // Fetch the HTML content from the server
    const response = await axios.get(`/case-summary/${caseId}/`);
    const html = response.data;

    // Create a temporary container for the HTML
    const tempElement = document.createElement('div');
    tempElement.style.display = 'none';
    tempElement.innerHTML = html;
    const source = tempElement.innerHTML;

    // Define options for html2pdf
    const pdfOptions = {
      filename: 'case-summary.pdf',
      margin: [15, 15],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      autoPaging: 'text',
    };

    // Handle download differently for iOS and other devices
    if (isIOS()) {
      // For iOS, generate the PDF as a blob and create a direct download link
      html2pdf()
        .from(source)
        .set(pdfOptions)
        .output('blob')
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          const url = window.URL.createObjectURL(pdfBlob);

          // Create an anchor element for the download
          const link = document.createElement('a');
          link.href = url;
          link.download = 'case-summary.pdf'; // Set the filename

          // Programmatically click the link to trigger download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Clean up the URL
          setTimeout(() => URL.revokeObjectURL(url), 100);
        });
    } else {
      // For non-iOS devices, download the PDF directly
      html2pdf().from(source).set(pdfOptions).save();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error downloading the case summary:', error);
  }
};

export const setEqualHeight = (selector) => {
  const elements = document.querySelectorAll(selector);
  let maxHeight = 0;

  elements.forEach((element) => {
    element.style.minHeight = 'auto';
  });

  elements.forEach((element) => {
    const elementHeight = element.offsetHeight;
    if (elementHeight > maxHeight) {
      maxHeight = elementHeight;
    }
  });

  elements.forEach((element) => {
    element.style.minHeight = maxHeight + 'px';
  });
};
