import '../styles/main.scss';
import '../styles/tailwind.css';
import CookieConsent from '../utils/cookieconsent';
import HandleDisabledCertificate from '../utils/handle-disabled-certificate';
import HandleSelect from '../utils/handle-select';
import { generateCSSVariables, setEqualHeight } from '../utils/helpers';
import TogglePassword from '../utils/toggle-password';
import './onboarding';

/**
 * Run Script on site load
 */
document.addEventListener('DOMContentLoaded', () => {
  (() => {
    generateCSSVariables();
    TogglePassword.ready();
    HandleSelect.ready();
    CookieConsent.ready();
    HandleDisabledCertificate.ready();
    setEqualHeight('.navbar__link');
    window.addEventListener('resize', function () {
      setEqualHeight('.navbar__link');
    });
  })();
});
