class HandleDisabledCertificate {
  ready() {
    const disabledLinks = document.querySelectorAll('.navbar__link--disabled');

    disabledLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
      });
    });
  }
}

export default new HandleDisabledCertificate();
